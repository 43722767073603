import React, { useContext, useState } from 'react';
import { AppContext } from '~/providers/AppProvider';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { SearchInput } from '@boatsetter-backup/js-component-lib';
import queryString from 'query-string';
import { getCultureToken } from '~/utils/langUtils';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

interface Props {
  expandSearchBar?: boolean;
}

export const tokenizeSearchLocationString = (location: string) => {
  // Here we indroduced a try catch block, do we still need the isEmpty? Or in this case always return locationstring
  try {
    const locationString = location.split(',').map((t) => t.toLowerCase().trim());
    return location?.length === 0 ? [] : locationString;
  } catch (err) {
    return [];
  }
};

export const locationIsDomesticSearch = (tokens: string[]) =>
  tokens.some((el) => el === 'usa' || el === 'united states' || (el.includes('united') && el.includes('states')));

export const redirectBackToBS4 = (place) => {
  const isDomestic = locationIsDomesticSearch(tokenizeSearchLocationString(place.near));
  if (isDomestic) {
    const bs4Query = {
      action: 'global-search',
      lat_lng_ne: place.ne_lat + ',' + place.ne_lng,
      lat_lng_sw: place.sw_lat + ',' + place.sw_lng,
      near: place.near,
    };

    window.location.href = 'https://www.boatsetter.com/a/boat-rentals/search?' + queryString.stringify(bs4Query);
    return true;
  }
  return false;
};

const SearchGoogleInput = ({ expandSearchBar }: Props) => {
  const router = useRouter();
  const appCtx = useContext(AppContext);
  const [searchReady, setSearchReady] = useState(false);

  const getSearchPlace = (place) => {
    appCtx.actions.setLoading(true);

    const searchQuery = {
      latitudeSw: place.sw_lat,
      longitudeSw: place.sw_lng,
      latitudeNe: place.ne_lat,
      longitudeNe: place.ne_lng,
      near: place.near,
      // ...adjustSearchingPerimeter(place.ne_lat, place.sw_lat, place.ne_lng, place.sw_lng),
      currency: appCtx.state.currency,
    };

    if (redirectBackToBS4(place)) return;

    localStorage?.setItem('newSearch', 'true');

    if (appCtx.state.defaultCurrency === appCtx.state.currency) {
      delete searchQuery.currency;
    }

    router.push({
      pathname: `/${getCultureToken(appCtx)}search`,
      query: searchQuery,
    });
  };
  return (
    <SearchWrapper className={expandSearchBar ? 'expanded' : ''}>
      <SearchInput
        dispatch={() => {}}
        icon={() => <img width={20} alt="search" src={`${publicRuntimeConfig.ASSETS_PREFIX}/images/search.svg`} />}
        location={{
          pathname: 'search',
        }}
        resetMap={function noRefCheck() {}}
        searchRedirect={getSearchPlace}
        setSearchBarOpen={setSearchReady}
        searchOpen={searchReady}
        placeholder="Where do you want to boat?"
      />
    </SearchWrapper>
  );
};

const SearchWrapper = styled.div`
  max-width: 400px !important;
  width: 100%;

  div[class*='_SearchInputContainer'] {
    width: 100%;
  }
  &.expanded {
    div[class*='_SearchInput'] {
      margin-right: auto;
      visibility: visible !important;
      opacity: 1 !important;
      width: 100% !important;
    }
  }

  input {
    height: 42px;
    font-family: inherit;
    font-size: 14px;
    width: 100% !important;
  }
`;
export default SearchGoogleInput;
