import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Search from '~/components/homepage/search';

const VideoBackground = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    videoRef?.current?.play();
  }, []);

  return (
    <VideoWrapper id="video_bg">
      <Video
        ref={videoRef}
        autoplay="autoplay"
        loop
        muted
        plays-inline
        poster="https://d19sakry2g7lg9.cloudfront.net/global-video-cover.png"
      >
        <source
          src="https://d3huccplrcwe3q.cloudfront.net/BoatSetter.Static/dist/app/assets/hp-video.av1.mp4"
          type="video/mp4; codecs=av01.0.05M.08"
        />
        <source
          src="https://d3huccplrcwe3q.cloudfront.net/BoatSetter.Static/dist/app/assets/hp-video.h265.mp4"
          type="video/mp4; codecs=hevc"
        />
        <source
          src="https://d3huccplrcwe3q.cloudfront.net/BoatSetter.Static/dist/app/assets/hp-video.vp9.webm"
          type="video/webm; codecs=vp9"
        />
        <source
          src="https://d3huccplrcwe3q.cloudfront.net/BoatSetter.Static/dist/app/assets/hp-video.h264.mp4"
          type="video/mp4; codecs=avc1.4D401E"
        />
      </Video>
      <Search />
    </VideoWrapper>
  );
};

const VideoWrapper = styled.div`
  height: 75vh;
  max-height: 680px;

  position: relative;
  justify-content: center;
  margin-top: -60px;

  background: transparent linear-gradient(180deg, #284166b8 0%, #1f334f85 15%, #14213347 100%) 0% 0% no-repeat
    padding-box;
`;

const Video = styled.video`
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
`;

export default VideoBackground;
