import React from 'react';
import { SingleSelect } from '@boatsetter-backup/js-component-lib';
import styled from 'styled-components';
import { defaultFilterValues } from '~/api/config';
import { Trans, withI18n } from '@lingui/react';
import { t } from '@lingui/macro';

const passengersArr = [...Array(defaultFilterValues.maxPassengers)].map((_, i) => i + 1);

interface Option {
  value: string;
  label: string;
}

interface Props {
  setPassengerCount: any;
  i18n: any;
}

const PassengersCount = React.memo(({ setPassengerCount, i18n }: Props) => {
  const passengersObj = [];
  passengersArr.map((passengerNum) => {
    passengersObj.push({
      label:
        passengerNum === 1
          ? `${passengerNum} ${i18n._(t`global.passengers.singular`)}`
          : `${passengerNum} ${i18n._(t`global.passengers.plural`)}`,
      value: passengerNum,
    });
  });

  const changePassengersCount = (newPassengerCount: Option) => {
    setPassengerCount(newPassengerCount.value);
  };

  return (
    <PassengerInputWrapper>
      <SingleSelect
        instanceId={'passengers_select'}
        options={passengersObj}
        placeholder={<Trans id="boat.passengers" />}
        onChange={changePassengersCount}
      />
    </PassengerInputWrapper>
  );
});

export default withI18n()(PassengersCount);

const PassengerInputWrapper = styled.div`
  width: 200px;
  border-radius: 0px;

  div[class*='control'] {
    border-radius: 0px;
    border: 1px solid hsl(0, 0%, 80%);
    height: 48px;
    cursor: pointer;
    box-shadow: none !important;
  }

  span[class*='indicatorSeparator'] {
    display: none;
  }

  div[class*='indicatorContainer'] {
    color: #0751c2;
  }

  div[class*='ValueContainer'] {
    input {
      color: transparent !important;
    }
  }

  @media screen and (max-width: 960px) {
    display: none;
  }
`;
