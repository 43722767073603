import React, { useContext, useState, useRef } from 'react';
import { AppContext } from '~/providers/AppProvider';
import styled from 'styled-components';
import { SearchCalendar } from '@boatsetter-backup/js-component-lib';
import moment from 'moment';
import { useOnClickOutside } from '~/hooks/useOnClickOutside';
import { getFormattedDate } from '~/utils/dateUtils';
import { Trans } from '@lingui/react';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

interface Props {
  setSearchDate: any;
}

const DateInput = React.memo(({ setSearchDate }: Props) => {
  const appCtx = useContext(AppContext);
  const calendarRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(undefined);

  const [renderedDate, setRenderedDate] = useState(selectedDate ? moment(selectedDate, 'YYYY-MM-DD') : moment());

  const [showCalendar, setShowCalendar] = useState(false);
  useOnClickOutside(calendarRef, () => {
    setShowCalendar(false);
  });

  const toggleCalendar = () => {
    setShowCalendar((prev) => !prev);
  };

  const chooseDate = (date) => {
    setSelectedDate(date);
    setSearchDate(date);
    setShowCalendar(false);
  };
  return (
    <Wrapper>
      <CalendarInput
        onClick={() => {
          toggleCalendar();
        }}
        className={selectedDate !== undefined ? 'selected' : ''}
      >
        {selectedDate === undefined ? (
          <Trans id="boatsFilter.date" />
        ) : (
          getFormattedDate(selectedDate, appCtx.state.lang)
        )}
      </CalendarInput>
      <CalendarIcon
        src={`${publicRuntimeConfig.ASSETS_PREFIX}/images/icons/carret_down.svg`}
        onClick={() => {
          toggleCalendar();
        }}
      />

      {showCalendar && (
        <CalendarWrapper ref={calendarRef}>
          <SearchCalendar
            allowFuture
            blockedDates={[]}
            bottomComponents={{}}
            dayNameShortcuts={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
            hideNextMonthArrow
            hidePrevMonthArrow
            localeShortcut={appCtx.state.lang}
            numMonthsToShow={1}
            onDateClick={chooseDate}
            onMonthChange={(month) => {
              setRenderedDate(month);
            }}
            renderedDate={renderedDate}
            selectedDate={selectedDate}
            showHeader
            showOverlay
          />
        </CalendarWrapper>
      )}
    </Wrapper>
  );
});

export default DateInput;

const CalendarInput = styled.div`
  width: 200px;
  background-color: white;
  color: hsl(0, 0%, 50%);
  height: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-radius: 0px;
  border: 1px solid hsl(0, 0%, 80%);
  border-right: 0px;
  border-left: 0px;

  &.selected {
    color: black;
  }
`;

const CalendarWrapper = styled.div`
  position: absolute;
  width: 300px;
  top: 57px;
  background-color: white;

  &:after {
    left: 8px;
    top: -9px;
    border: 9px solid transparent;
    border-top: none;
    border-bottom-color: #fff;
    display: inline-block;
    position: absolute;
    bottom: auto;
    content: '';
  }
`;

const CalendarIcon = styled.img`
  position: absolute;
  right: 8px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;
