import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SearchInput } from '@boatsetter-backup/js-component-lib';
import { withI18n } from '@lingui/react';
import { t } from '@lingui/macro';

interface Props {
  searchError: boolean;
  setSearchPlace: any;
  i18n: any;
}
const PlaceSearch = ({ setSearchPlace, searchError, i18n }: Props) => {
  const [searchReady, setSearchReady] = useState(false);

  useEffect(() => {
    if (searchError) {
      document.getElementById('place_input').focus();
    }
  }, [searchError]);

  const getSearchPlace = (place) => {
    setSearchPlace(place);
  };

  return (
    <SearchWrapper searchError={searchError}>
      <SearchInput
        dispatch={() => {}}
        icon={() => {
          return (
            <i
              style={{
                position: 'absolute',
                left: '31px',
                color: '#8e9697',
              }}
              className="Icon Icon-map-marker"
            ></i>
          );
        }}
        location={{
          pathname: 'search',
        }}
        style={{ borderRadius: '8px' }}
        resetMap={function noRefCheck() {}}
        searchRedirect={getSearchPlace}
        setSearchBarOpen={setSearchReady}
        placeholder={`${i18n._(t`global.location`)}`}
      />
    </SearchWrapper>
  );
};

const SearchWrapper = styled.div`
  min-width: 200px;
  border-radius: 8px;

  div[class*='SearchInputContainer_'] {
    width: 100% !important;
  }

  div[class*='SearchInput'] {
    width: 100% !important;
    visibility: visible !important;
    opacity: 1 !important;
    margin-top: 0px !important;
  }

  @media only screen and (min-width: 640px) {
    input {
      width: 400px !important;
    }
  }

  input {
    font-size: 16px !important;
    line-height: 1.4;
    background-color: white;
    color: black;
    border-radius: 0px;
    border: 1px solid hsl(0, 0%, 80%);
    height: 48px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    font-family: 'AvenirNext', 'Avenir', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    padding-left: 35px;

    @media only screen and (min-width: 640px) {
      width: 400px !important;
    }
  }

  input::placeholder {
    opacity: 1; /* Firefox */
    color: hsl(0, 0%, 50%) !important;
    font-size: 16px;
    color: ${(props) => (props.searchError ? 'red !important' : 'inherit')};
  }

  div[class*='SearchFormPrefix'] {
    display: flex !important;
  }
`;

export default withI18n()(PlaceSearch);
