import React, { useContext, useEffect } from 'react';
import { AppContext } from '~/providers/AppProvider';
import { getPromotedRegions } from '~/api/services/regions';
import SEO from '~/components/seo';
import styled from 'styled-components';
import VideoBackground from '~/components/videoBackground';
import Layout from '~/components/layout';

import dynamic from 'next/dynamic';
const RentingIsEasy = dynamic(async () => await import('~/components/homepage/rentingIsEasy'));
const FeaturedDestinations = dynamic(async () => await import('~/components/homepage/featuredDestinations'));

export default function Home({ regions }) {
  const appCtx = useContext(AppContext);

  useEffect(() => {
    appCtx.actions.setLoading(false);
  }, []);

  return (
    <Layout>
      <SEO />
      <VideoBackground />
      <Wrapper>
        <RentingIsEasy />
        {regions && <FeaturedDestinations regions={regions} />}
      </Wrapper>
    </Layout>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

// export async function getStaticPaths() {
//     const paths = [
//         { params: { lang: 'en' } },
//         { params: { lang: 'es' } },
//         { params: { lang: 'de' } },
//     ];
//     return { paths: paths, fallback: false };
// }

// export async function getStaticProps(context) {
//     let regions = null;

//     await getPromotedRegions({
//         xLanguage: context.params.lang,
//     })
//         .then((response) => {
//             response;
//             regions = response.data;
//         })
//         .catch((e) => {
//             console.error(e);
//         });

//     return {
//         props: {
//             regions: regions,
//         },
//     };
// }

export async function getServerSideProps({ params, res, req, query }) {
  let regions = null;

  await getPromotedRegions({
    xLanguage: query.lang,
  })
    .then((response) => {
      regions = response.data;
    })
    .catch((e) => {
      console.error(e);
    });

  return {
    props: {
      regions,
    },
  };
}
