const getFormattedDateString = (date: any, lang: string) => {
  const dateFormatter = new Intl.DateTimeFormat(lang, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return dateFormatter.format(date);
};

const getFormattedDate = (date: any, lang: string) => {
  const dateFormatter = new Intl.DateTimeFormat(lang, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });

  return dateFormatter.format(date);
};

const getFormattedDates = (dates: any[], lang: string) => {
  const datesArr = [];

  dates.slice(0, 3).forEach((date) => {
    datesArr.push(getFormattedDate(date, lang));
  });

  const dots = dates.length > 3 ? '...' : '';

  return datesArr.join() + dots;
};
export { getFormattedDate, getFormattedDates, getFormattedDateString };
